.form-wrapper {
    z-index: 999;

    .form-container {
        background-color: white;
        position: relative;

        .stepDots-container {
            background-color: $secondary;
        }
        .chakra-input__right-addon {
            background-color: $secondary;
        }
    }
}
@media only screen and (max-width: 800px) {
    .form-container {
        margin-top: 0 !important;
        box-shadow: 0px 0px 20px -2px #d6d6d6 !important;
    }
}

@media only screen and (max-width: 550px) {
    .form-container {
        margin: 0 !important;
        padding: 1rem !important;
    }
}

@media only screen and (max-width: $xsmall) {
    .form-container {
        .css-1y5q8ss {
            flex-direction: column !important;
            align-items: flex-start;
            .chakra-radio {
                margin-bottom: 0.5rem !important;
                margin-left: 0 !important;
            }
        }
        & div[role="radiogroup"] {
            grid-gap: 0.5rem;
            font-size: 0.8rem;
        }
    }
}
