.primary-btn {
    background-color: $primary !important;
    width: 300px;
    padding: 20px;
    @media (max-width: $small) {
        width: 230px;
    }
}

.secondary-btn {
    background-color: $primary !important;
}

.btn-outline {
    color: white;
    &:hover {
        background-color: transparent !important;
        opacity: 0.8;
    }
}
