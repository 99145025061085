html {
    scroll-behavior: smooth;
}

.pulse {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 20px;
    text-align: center;
    width: 100%;
    max-width: 350px;
    border-radius: 10px;
    background: $primary;
    border: 2px solid $primary;
    animation: pulse 2s infinite;
    transition: all 0.3s ease-in-out;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(32, 74, 181, 0.5);
    }
    100% {
        box-shadow: 0 0 0 25px rgba(251, 77, 61, 0);
    }
}
.chakra-radio__control[data-checked] {
    background-color: $primary !important;
    border-color: $primary !important;
}
.center-item {
    margin: 4rem 0;
    text-align: center;
    @media only screen and (max-width: $small) {
        margin: 2rem 0;
    }
}
@media only screen and (max-width: $medium) {
    .main-contianer .css-14xmlw6 {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }
    .main-contianer .css-x4kbrg {
        padding: 1rem 0;
    }
}

.css-isk0hu:focus,
.css-isk0hu[data-focus] {
    box-shadow: none !important;
}

input:focus {
    outline: $primary;
    border-color: $primary !important;
    box-shadow: none !important;
}

.fill-primary {
    fill: $primary;
}
.stroke-primary {
    stroke: $primary;
}
.fill-secondary {
    fill: $secondary;
}

// Temp here

.text-icon-conatiner {
    .icon-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        & div {
            &:nth-child(1) {
                width: 30px;
                margin-right: 1rem;
                svg {
                    color: $primary;
                }
            }
            &:nth-child(2) {
                width: calc(100% - 50px);
            }
        }
    }
}

.group-selection {
    .group-selection-inner {
        position: relative;
        color: black!important;
        .body-type-media {
            img {
                width: 100%;
                height: 250px;
                margin-top: 1rem;
            }
        }
        &[data-checked] {
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fb4d3d' focusable='false' class='chakra-icon css-6tzxjd'%3E%3Cpath d='M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z'%3E%3C/path%3E%3C/svg%3E");
                width: 20px;
                height: 20px;
                position: absolute;
                right: 2px;
                top: 2px;
            }
        }
    }
}
